<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
    >
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";

import { prjblacklistuserlist } from "@/api/block/personnel";
import { prjblacklistorglist } from "@/api/block/organization";
export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      tableData: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          label: "",
          prop: "prjName",
          span: 4,
          placeholder: "项目名称",
        },
      ],
      pageId: "",
      pageType: "",
    };
  },
  computed: {
    tableOption() {
      let arr = [
        {
          label: "项目编号",
          prop: "prjCode",
          align: "center",
          overHidden: true,
        },
        {
          label: "项目名称",
          prop: "prjName",
          align: "center",
          overHidden: true,
        },
        {
          label: "归属组织",
          prop: "companyName",
          align: "center",
          overHidden: true,
        },
        {
          label: "变更状态",
          prop: "blState",
          align: "center",
          dataType: "number",
          // dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_state`,
          dicData: [
            { dictValue: "加入黑名单", dictKey: 1 }, // FINISHED
            { dictValue: "解除黑名单", dictKey: 0 }, // PREPARE
          ],
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          overHidden: true,
        },
        {
          label: "变更时间",
          prop: "updateTime",
          align: "center",
          overHidden: true,
        },
      ];
      // if (this.pageType == "relatedPersonnel") {
      //   arr.splice(1, 0, {
      //     label: "归属组织",
      //     prop: "companyName",
      //     align: "center",
      //     overHidden: true,
      //   });
      // }
      if (this.pageType == "relatedOrg") {
        arr.splice(2, 1);
      }
      return {
        selection: false,
        // linklabel: "partnerUserName",
        menu: false,
        column: arr,
      };
    },
  },
  methods: {
    init(id, pageType) {
      // if (this.pageType == "relatedOrg") {
      //   this.tableOption.column.splice(2, 1);
      // } else {
      // }
      this.pageId = id;
      this.pageType = pageType;
      this.onLoad(this.page);
    },
    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;

      let reqApi =
        this.pageType == "relatedOrg"
          ? prjblacklistorglist
          : prjblacklistuserlist;
      if (this.pageType == "relatedOrg") {
        params.companyCode = this.pageId;
      } else {
        params.userId = this.pageId;
      }
      reqApi(params).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data;
        }
      });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
  },
};
</script>
<style scoped lang="scss"></style>
